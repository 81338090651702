
import { Component, Vue } from "vue-property-decorator";

import GenerationsTable from "@/components/Core/Table/Localisation/GenerationsTable.vue";

@Component({
  components: {
    GenerationsTable,
  },
})
export default class Generations extends Vue {}
