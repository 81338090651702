
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { GenerationDetails, GenerationInfos, LITE_FEATURE } from "@/domain";

import { IGenerationService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class GenerationsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get generationService() {
    return this.container.resolve<IGenerationService>(S.GENERATION_SERVICE);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Localisation/GenerationCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Localisation/GenerationUpdate.vue");
  }

  get existingNames() {
    return _.map(this.generations, (g) => g.name);
  }

  get headers() {
    return [
      {
        text: "Nom",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  generations: GenerationInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.generations = await this.generationService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(generation: GenerationDetails) {
    this.generations.push(GenerationInfos.fromDetails(generation));
  }

  update(generation: GenerationDetails) {
    const index = _.findIndex(this.generations, (g) => g.id == generation.id);
    if (index != -1) {
      this.generations.splice(
        index,
        1,
        GenerationInfos.fromDetails(generation)
      );
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const generation = _.find(this.generations, (g) => g.id == id)!;
      const updatedGeneration = await this.generationService.update(id, {
        name: generation.name!,
        disabled: !generation.disabled,
      });
      this.update(updatedGeneration);
    } finally {
      this.loading = false;
    }
  }
}
